import React, { Component, useCallback, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Tab,
  Tabs,
  Table,
  Button,
  Form,
  Alert,
  FloatingLabel
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'
import logoBePass from '../../assets/images/bepass_logo_default.png'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'
import { VisitorCreate } from '../components/visitorCreate'
import { AccessLog } from '../components/accessLog'
import { FindUser } from '../components/findUser'
import { OpenGate } from '../components/openGate'
import { FindUserFull } from '../components/findUserFull'
import { TotalUsers } from '../components/totalUsers'
import { DailyAccess } from '../components/dailyAccess'
import { GateStatus } from '../components/gateStatus'
import { useForm } from 'react-hook-form'

import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'

function Login() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
    reset
  } = useForm()

  const auth = sessionStorage.getItem('auth')
  if (auth === 'true') {
    window.location.href = '/control'
  }

  const [loginError, setLoginError] = useState({
    status: false,
    message: ''
  })

  const onSubmit = (data: any) => {
    // if (data.user === 'teste@bepass.com' && data.password === 'test123') {
    //   sessionStorage.setItem('auth', 'true')
    //   return (window.location.href = '/dashboard')
    // }

    setLoginError({
      status: false,
      message: ''
    })

    fetch(`${BASE.api.base_url}${BASE.api.access_login}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify({
        login: data.user,
        password: data.password
      })
    })
      .then((resg) => resg.json())
      .then((response) => {
        if (response.token) {
          sessionStorage.setItem('auth', 'true')
          sessionStorage.setItem('gate', (response.gate || '99267'))
          sessionStorage.setItem('token', response.id)
          sessionStorage.setItem('name', response.name)
          sessionStorage.setItem('role', 'cb2979b2')
          return (window.location.href = '/control')
        }

        setLoginError({
          status: true,
          message: 'Usuário ou senha inválidos'
        })
      })
      .catch((error) => {
        setLoginError({
          status: true,
          message: 'Usuário ou senha inválidos'
        })
      })
  }

  return (
    <React.Fragment>
      <div className="auth-wrapper">
        <div className="auth-content">

          <Card className="borderless text-center">
            <Card.Body>
              <div className="mb-4">
                  <img src={logoBePass} className='login-logo' />
              </div>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FloatingLabel
                  className="mb-3"
                  controlId="floatingPassword"
                  label="Usuário"
                >
                  <Form.Control
                    type="text"
                    placeholder="Usuário"
                    {...register('user', { required: true })}
                  />
                  {errors.user && (
                    <Alert variant="danger">Por favor, informe um e-mail</Alert>
                  )}
                </FloatingLabel>

                <FloatingLabel
                  className="mb-3"
                  controlId="floatingPassword"
                  label="Senha"
                >
                  <Form.Control
                    type="password"
                    placeholder="Senha"
                    {...register('password', { required: true })}
                  />
                  {errors.password && (
                    <Alert variant="danger">Por favor, informe uma senha</Alert>
                  )}
                </FloatingLabel>

                {/* <Form.Group
                  controlId="exampleForm.ControlSelect1"
                  className="mb-2"
                >
                  <select
                    className="form-control"
                    {...register('gate', { required: true })}
                  >
                    <option value="Matarazzo">Matarazzo</option>
                    <option value="Palestra">Palestra</option>
                    <option value="Conveniencia">Conveniência</option>
                    <option value="Estacionamento">Estacionamento</option>
                    <option value="Operacional">Operacional</option>
                  </select>
                </Form.Group> */}

                {loginError.status && (
                  <Alert variant="danger">{loginError.message}</Alert>
                )}

                <Form.Group>
                  <Button
                    type="submit"
                    className="mb-0 mt-4 w-100 btn-success"
                  // onClick={() => clearErrors()}
                  >
                    Entrar
                  </Button>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Login
